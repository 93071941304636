import { useState, useEffect } from 'react';
import { FilePond, registerPlugin } from 'react-filepond'
import { useForm } from "react-hook-form";
import { useRecaptcha } from "react-hook-recaptcha";

import request from '../utils/request';
import configData from '../config'
import Modal from "../components/modal"

import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginFileValidateType)

const sitekey = "6Ldz830eAAAAAHiXYQ6WVEjofKW6s1mKk-qWzIbX";  // change to your sitekey
const containerId = "nd-recaptcha"

export default function Form() {

    const { register, handleSubmit, formState: { errors }, reset, setError } = useForm();
    const [files, setFiles] = useState([])

    const [noFile, setNoFile] = useState(false)
    const [noCaptcha, setNoCaptcha] = useState(false)
    // const [isUploading, setIsUploading] = useState(false);
    const [btnStatus, setBtnStatus] = useState("register");
    const [categories, setCategories] = useState([])
    const [status, setStatus] = useState("available")
    const [captchaResponse, setCaptchaResponse] = useState(null);
    const [isOpen, setIsOpen] = useState(false)


    const categoryChanged = (selected) => {

        if (selected !== "0") {
            setBtnStatus("check_seats")
            request({
                method: "get",
                url: `${configData.SERVER_URL}/seats`,
                params: {
                    category: selected
                },
                headers: { "Content-Type": "multipart/form-data" },
            }).then(({ status, data }) => {
                setBtnStatus("register")
                if (status === 200) {
                    setStatus(data.status)
                }

            }).catch(console.log)

        }
        else {
            setBtnStatus("register")
        }

    }


    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const successCallback = (response) => {
        setCaptchaResponse(response);
    };
    const expiredCallback = () => setCaptchaResponse(null);

    useRecaptcha({
        containerId,
        successCallback,
        expiredCallback,
        sitekey,
        size: "normal",
    });

    useEffect(() => {
        fetch(`${configData.SERVER_URL}/category`)
            .then(res => res.json()).then(categories_ => {
                setCategories(categories_)
            })
    }, [])


    async function uploadInfo(data) {


        if (data.category === "0") {
            setError("category", {
                type: "manual",
                message: "Please select category",
            });
            return false
        }

        if (files.length <= 0) {
            setNoFile(true)
            return false
        } else { setNoFile(false) }

        if (!captchaResponse) {
            setNoCaptcha(true)
            return false
        } else { setNoCaptcha(false) }





        let formData = new FormData()
        formData.append("name", data.name);
        formData.append("age", data.age);
        formData.append("gender", data.gender);
        formData.append("category", data.category);
        formData.append("phone", data.phone);
        formData.append("email", data.email);
        formData.append("recaptcha", captchaResponse);
        files.forEach((element) => {
            formData.append("id_proofs", element.file, element.filename);
        })

        request({
            method: "post",
            url: `${configData.SERVER_URL}/register-information`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(({ status, data }) => {

            setBtnStatus("register")
            if (status === 200) {
                reset()
                setFiles([])
                openModal()
            }

        }).catch(() => {
            setBtnStatus("register")
        })

        setBtnStatus("uploading")

    }

    return (
        <>
            <form className="w-full max-w-lg md:w-96 " onSubmit={handleSubmit(uploadInfo)}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="label" dir='rtl' htmlFor="name">
                            الاسم
                            <span className='text-red-600'>*</span>
                        </label>
                        <input

                            id="name"
                            {...register('name', { required: true })}
                            className={`text-box rtl  ${errors.name ? "border-red-500" : "border-gray-200"}`}
                            dir="rtl"
                            type="text"
                            placeholder="الاسم" />
                        {errors.name && <p className="validation-error">Please fill out this field.</p>}

                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="label" dir='rtl' htmlFor="age">
                            العمر <span className='text-red-600'>*</span>
                        </label>
                        <input
                            className={`text-box  ${errors.age ? "border-red-500" : "border-gray-200"}`}
                            {...register('age', { required: true })}
                            id="age"
                            type="number"
                            dir='rtl'
                            placeholder="العمر"
                        />
                        {errors.age && <p className="validation-error">Please fill out this field.</p>}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="label" dir='rtl' htmlFor="gender">
                            الجنس
                        </label>
                        <div className="relative">
                            <select id="gender"
                                {...register('gender')}
                                className="block appearance-none w-full  border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                            >
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="label" dir='rtl' htmlFor="category">
                            الفئة<span className='text-red-600'>*</span>
                        </label>
                        <div className="relative">
                            <select id="category"
                                {...register('category', { required: true })}
                                onChange={event => categoryChanged(event.target.value)}
                                className={`block appearance-none w-full  border ${errors.category ? "border-red-500" : "border-gray-200"} text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white`}
                            >
                                <option value="0">Choose category</option>
                                {categories && categories.map(category => <option key={category._id} value={category._id}>{category.category}</option>)}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                        {errors.category && <p className="validation-error">Please select category</p>}
                    </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="label" dir='rtl' htmlFor="phone">
                            رقم الجوال <span className='text-red-600'>*</span>
                        </label>
                        <input id="phone"
                            dir='rtl'
                            {...register('phone', { required: true })}
                            className={`text-box  ${errors.phone ? "border-red-500" : "border-gray-200"}`} type="number" placeholder="رقم الجوال" />
                        {errors.phone && <p className="validation-error">Please fill out this field.</p>}
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="label" dir='rtl' htmlFor="email">
                            الإيميل <span className='text-red-600'>*</span>
                        </label>
                        <input dir='rtl' id="email"    {...register('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            className={`text-box ${errors.email ? "border-red-500" : "border-gray-200"}`}
                            type="email" placeholder="الإيميل" />
                        {errors.email && <p className="validation-error">Please fill out this field.</p>}
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                        <label className="label" dir='rtl' htmlFor="email">
                            ارفاق صورة البطاقة الشخصية <span className='text-red-600'>*</span>
                        </label>
                        <FilePond
                            files={files}
                            onupdatefiles={setFiles}
                            allowMultiple={true}
                            maxFiles={3}
                            acceptedFileTypes={['image/jpeg', 'image/png']}
                            name="files"
                            labelIdle='ارفاق صورة البطاقة الشخصية'
                            credits={false}
                        />
                        {noFile && <p className="validation-error">Please upload id proof</p>}
                    </div>
                </div>
                <div className="flex flex-wrap  -mx-3 mb-6">
                    <div className="w-full px-3 text-center">
                        <div id={containerId} className="g-recaptcha flex justify-center" />
                        {noCaptcha && <p className="validation-error">Verify recaptcha</p>}
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                        {btnStatus === "uploading" && <button type="button" className="w-full h-12 inline-flex text-center items-center px-6 py-2 font-semibold leading-6 text-sm shadow  text-white bg-blue-500 rounded-lg focus:shadow-outline hover:bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            uploading...
                        </button>}
                        {btnStatus === "check_seats" && <button type="button" className="w-full h-12 inline-flex text-center items-center px-6 py-2 font-semibold leading-6 text-sm shadow  text-white bg-blue-500 rounded-lg focus:shadow-outline hover:bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Checking Seats ...
                        </button>}
                        {btnStatus === "register" && <button type='submit' disabled={status === "available" ? false : true} className={`w-full  h-12 px-6 font-semibold leading-6 text-sm  text-white transition-colors shadow duration-150 ${status === "available" ? "bg-blue-500 hover:bg-blue-600" : "bg-red-400 hover:bg-red-500"}  rounded-lg focus:shadow-outline `}> {status === "available" ? "Register" : "Registration full"}  </button>}
                    </div>
                </div>

            </form>
            <Modal isOpen={isOpen} closeModal={closeModal} />
        </>
    )


};
