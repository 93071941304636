import { useState } from 'react';

import Form from './components/form';


import logo from './assets/logo.svg'
import appStore from './assets/app_store.svg'
import playStore from './assets/play_store.svg'
import khebrat from './assets/khebrat.svg'

import left from './assets/left.png'
import right from './assets/right.png'
function App() {


  return (
    <div className="font-cairo antialiased lg:flex lg:flex-row ">
      <div className='w-full lg:w-1/2 flex justify-center bg-najah-blue min-h-screen bg-no-repeat bg-cover bg-right-bottom py-5' style={{ backgroundImage: `url('${left}')`, backgroundBlendMode: "multiply" }}>
        <div className='m-auto space-y-16 2xl:scale-100 px-5 md:p-0' >
          <div className='px-3'>
            <img src={logo} className="animate__animated animate__fadeInDown" />
          </div>
          <div className='flex justify-center px-8 md:p-0  '>
            <div className='space-y-2 '>
              <div>
                <a href='' className='cursor-pointer'>
                  <img src={appStore} className="animate__animated animate__fadeInUp" />
                </a>
              </div>
              <div>
                <a href='' className='cursor-pointer'>
                  <img src={playStore} className="animate__animated animate__fadeInUp" />
                </a>
              </div>
            </div>
            <div className='pl-5 place-content-center '>
              <img src={khebrat} className="animate__animated animate__fadeInUp" />
            </div>
          </div>
        </div>
      </div>
      <div className='w-full lg:w-1/2 flex justify-center bg-white min-h-screen bg-no-repeat bg-cover bg-left-bottom' style={{ backgroundImage: `url('${right}')`, backgroundBlendMode: "multiply" }}>
        <div className='m-auto py-5 px-2 md:p-3 2xl:scale-100'>
          <div className="bg-white shadow-md rounded-3xl p-5 border-2 border-najah-blue animate__animated animate__pulse animate__delay-2s">
            <Form />

          </div>
        </div>
      </div>
    </div >
  );
  // return (
  //   <div className="font-cairo antialiased flex flex-col min-h-screen  bg-gradient-to-r from-sky-400 to-blue-500">
  //     <div className='flex justify-center m-5 md:m-10'>
  //       {/* <img src={logo} className="h-auto md:h-20" /> */}
  //       <h3 className='font-extrabold text-5xl text-white'>LOGO</h3>
  //     </div>
  //     <div className="flex justify-center">
  //       <div className="bg-white shadow-md rounded-3xl p-6 mx-3">
  //         <Form openModal={openModal} />
  //         <Modal isOpen={isOpen} closeModal={closeModal} />
  //       </div>
  //     </div >
  //   </div>
  // );
}

export default App;
